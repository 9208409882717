import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { useState, useEffect } from "react";
import { Col, Row, Alert } from "react-bootstrap";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export const Newsletter = ({ status, message, onValidated }) => {
  const [email, setEmail] = useState('');
  const [firebaseStatus, setFirebaseStatus] = useState(null);

  useEffect(() => {
    if (status === 'success') clearFields();
  }, [status])

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email && email.indexOf("@") > -1) {
      try {
        // Save to Firebase
        const docRef = await addDoc(collection(db, "newsletter"), { email: email });
        setFirebaseStatus({ type: 'success', message: 'Thanks for subscribing!' });
        
        // Call onValidated (if it's for a different service like Mailchimp)
        onValidated({ EMAIL: email });
        
        clearFields();
      } catch (error) {
        console.error("Error adding document: ", error);
        setFirebaseStatus({ type: 'error', message: 'Failed to save email. Please try again.' });
      }
    } else {
      setFirebaseStatus({ type: 'error', message: 'Please enter a valid email address.' });
    }
  }

  const clearFields = () => {
    setEmail('');
  }

  return (
    <Col lg={12}>
      <div className="newsletter-bx wow slideInUp">
        <Row>
          <Col lg={12} md={6} xl={5}>
            <h3>Subscribe to our Newsletter<br></br> & Never miss latest updates</h3>
            {status === 'error' && <Alert variant="danger">{message}</Alert>}
            {status === 'success' && <Alert variant="success">{message}</Alert>}
            {firebaseStatus && <Alert variant={firebaseStatus.type === 'success' ? 'success' : 'danger'}>{firebaseStatus.message}</Alert>}
          </Col>
          <Col md={6} xl={7}>
            <form onSubmit={handleSubmit}>
              <div className="new-email-bx">
                <input 
                  value={email} 
                  type="email" 
                  onChange={(e) => setEmail(e.target.value)} 
                  placeholder="Email Address" 
                />
                <button type="submit">Submit</button>
              </div>
            </form>
          </Col>
        </Row>
      </div>
    </Col>
  )
}
